.room {
  height: 100%;
}

.roomHeader {
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 80px;
}

.userHead {
  background: pink;
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.userAvatar {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.roomHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  z-index: 2;
  top: 0;
  padding: 40px 0;
  padding-top: 20px;
  margin-bottom: 20px;
  /* padding-top: 20px; */
  /* border: 2px solid red; */
  top: 5rem;
  background-image: linear-gradient(
    to top,
    rgba(18, 18, 18, 0),
    rgba(18, 18, 18, 1) 30%
  );
}

.left {
  display: flex;
  align-items: center;
}

.heading {
  font-size: 20px;
  font-weight: bold;
}

.heading {
  position: relative;
  margin-left: 20px;
}

.heading::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 60%;
  height: 4px;
  background-color: #0077ff;
  border-radius: 20px;
}

.goBack {
  background: none;
  outline: none;
  color: #fff;
}

.clientsWrap {
  display: flex;
  min-height: calc(100vh - 200px);
  border: 200px solide red;
  justify-content: space-between;
  margin: 0 1rem;
  gap: 20px;
}

.clientRight {
  width: 30%;
  min-width: 300px;
  background: #323232;
  border-radius: 20px;
  position: relative;
}

.clientLeft {
  position: relative;
  width: 100%;
  background: #253237;
  border-radius: 20px;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  outline: none;
  background: none;
}

.closeButton img {
}

.right {
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.serviceAccess {
  gap: 15px;
  display: flex;
  align-items: center;
}
.controls {
  gap: 15px;
  display: flex;
  align-items: center;
}

.serviceAccess button {
  background: #262626;
  border-radius: 50%;
  overflow: hidden;
  height: 50px;
  width: 50px;
}

.serviceAccess button img {
  object-fit: cover;
}

.serviceAccess .active {
  background: #0077ff;
}
.controls button {
  background: #262626;
  border-radius: 50%;
  overflow: hidden;
  height: 50px;
  width: 50px;
}

.controls button img {
  object-fit: cover;
}

.separator {
  width: 61px;
  height: 0px;
  transform: rotate(-90deg);
  /* transform-origin: 0 0; */
  border: 0.5px #323232 solid;
}

.leave {
  cursor: pointer;
}
