.consoleBox {
  height: 63vh;
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  padding: 20px;
  background-color: #212121;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}

.console {
  flex: 1;
  margin-right: 20px;
  color: #e0e0e0;
  /* border: 2px solid red; */
  width: 60%;
}

.console span {
  font-size: 16px;
  font-weight: bold;
}

.scrollableContainer {
  flex: 1;
  height: 52vh;

  margin-top: 10px;
  padding: 10px;
  background-color: #000;
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
  overflow: auto;
}

.pre {
  margin: 0;
  font-size: 14px;
  white-space: pre-wrap;
}

.outputMessage {
  margin-bottom: 10px;
  padding: 10px;
  overflow: auto;
  width: auto;
  background-color: #333;
  border-radius: 4px;
}

.outputMessage div {
  margin-bottom: 5px;
  color: #e0e0e0;
}

.messageTime {
  font-size: 12px;
  color: #888;
}

.runner button {
  padding: 10px 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.runner button:hover {
  background-color: #0056b3;
}

.serviceHead {
  font-size: 25px;
  margin: 30px;
  font-weight: bold;
  color: #e0e0e0;
}

.prompt {
  color: #00ff00;
}

.prompt::before {
  content: ">>>";
}

.command {
  color: #fff;
}
