.roomHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  z-index: 2;
  top: 0;
  padding: 40px 0;
  padding-top: 20px;
  margin-bottom: 20px;
  /* padding-top: 20px; */
  /* border: 2px solid red; */
  top: 5rem;
  background-image: linear-gradient(
    to top,
    rgba(18, 18, 18, 0),
    rgba(18, 18, 18, 1) 30%
  );
}

.left {
  display: flex;
  align-items: center;
}

.heading {
  font-size: 20px;
  font-weight: bold;
}

.searchBox {
  background: #262626;
  border-radius: 20px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  min-width: 300px;
}

.searchInput {
  background: transparent;
  border: none;
  outline: none;
  padding: 10px;
  color: #fff;
  width: 100%;
}
.heading {
  position: relative;
}

.heading::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 60%;
  height: 4px;
  background-color: #0077ff;
  border-radius: 20px;
}

.startRoomButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #20bd5f;
  border-radius: 20px;
  padding: 5px 20px;
  color: #fff;
  transition: all 0.3s ease-in-out;
}

.startRoomButton span {
  font-size: 16px;
  margin-left: 10px;
}

.startRoomButton:hover {
  background: #0f6632;
}

.roomList {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.listWrap {
  background-image: linear-gradient(
    to bottom,
    rgba(18, 18, 18, 0),
    rgba(18, 18, 18, 1) 90%
  );
}
