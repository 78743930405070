.cardWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 7rem;
}

.bottomPara {
  color: #c4c5c5;
  width: 70%;
  margin: 0 auto;
}
