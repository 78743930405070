.navbar {
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 2;
  background: #121212;
}

.logout {
  background: none;
  cursor: pointer;
}

.navRight {
  display: flex;
  align-items: center;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid #0077ff;
  margin: 0 10px;
  margin-right: 10px;
}
