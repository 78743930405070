.buttonWrap {
  display: flex;
  align-item: center;
  justify-content: flex-end;
}

.cardWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 7rem;
  /* width: 1500px; */
}

.tabButton {
  width: 60px;
  height: 60px;
  background: #262626;
  border: none;
  outline: none;
  margin: 10px 5px;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s;
}

.tabButton:hover {
  background: #504f54;
}

.active {
  background: #0077ff;
}

.actionButtonWrap {
  margin: 35px;
}

.bottomPara {
  margin-top: 10px;
  color: #c4c5c5;
  width: 70%;
  margin: 0 auto;
}

.card {
}
