html {
  font-size: 16px;
  overflow: scroll;
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

* {
  box-sizing: border-box;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
}

ol,
ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

body {
  background: #121212;
  font-family: "Nunito", sans-serif;
  color: #fff;
}

.container {
  width: 1200px;
  max-width: 90%;
  margin: 0 auto;
}

.cardWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 140px);
}

button {
  border: none;
  outline: none;
  cursor: pointer;
  font-weight: bold;
}
