/* ClientBox.module.css */

.clientList {
  /* padding: 30px; */
}

.userHead {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 20px;
  /* border: 1px solid #fff; */
}

.imgWrap {
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;
  position: relative;
}

.imgWrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  flex: 1;
  transition: all 0.3s ease-in-out;
}

.imgWrap:hover::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
  border-radius: 50%;
}

.imgWrap:hover img {
  filter: brightness(70%);
}

.userHead span {
  /* font-weight: bold; */
  flex: 1;
  position: relative;
  font-size: 18px;
  margin-left: 10px;
}

.serviceHead::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 60%;
  height: 4px;
  background-color: #0077ff;
  border-radius: 20px;
}

.serviceHead {
  font-size: 25px;
  /* text-align: center */
  position: relative;
  margin: 30px;
  font-weight: bold;
}

.micBtn {
  flex: 1;
  justify-self: flex-end;
  width: 100%;
  height: 100%;
  /* max-width: 30px; */
  background: none;
  border-radius: 50%;
  cursor: auto;
}

.buttonWrap {
  width: 35px;
  height: 35px;
  border-radius: 5px;
}

.mymic {
  background: #0077ff;
}

.mymic .micBtn {
  cursor: pointer;
}
