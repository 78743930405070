.button {
  background: #0077ff;
  padding: 10px 20px;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s;
}

.button:hover {
  background: #014a9c;
}

.arrow {
  margin-left: 10px;
}
