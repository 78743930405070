.message {
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 3rem;
}

.cardWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 90vh;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
